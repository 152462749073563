<template>
  <div class="finance-account">
    <en-table-layout ref="enTableLayout" :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="交易类型" class="col-auto">
            <el-select v-if="parent_type !== 2" style="width: 150px" v-model="params.type" size="small"
              placeholder="请选择" clearable>
              <el-option v-for="(item, index) in orderOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-else style="width: 150px" v-model="params.buy_cancel" size="small" placeholder="请选择" clearable>
              <el-option label="订单支出" :value="1" />
              <el-option label="取消订单退回" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间范围" class="col-auto">
            <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="parent_type == 2" label="提货类型" class="col-auto">
            <el-select style="width: 150px" v-model="params.type" size="small" placeholder="请选择" clearable>
              <el-option label="餐类商品订单" :value="4" />
              <el-option label="0元商品订单" :value="9" />
              <el-option label="自提商品订单" :value="5" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="[1, 4].includes(parent_type)" label="是否涉及卡劵支付" class="col-auto">
            <el-select style="width: 100px" v-model="params.is_card" size="small" placeholder="请选择" clearable>
              <el-option label="全部" value />
              <el-option label="涉及" :value="1" />
              <el-option label="不涉及" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-if="parent_type !== 3" style="width: 315px" size="medium" placeholder="请输入关键词" v-model="keyword"
              clearable>
              <el-select slot="prepend" v-model="key_word" style="width: 120px">
                <el-option value="serial_number" label="流水号"></el-option>
                <el-option value="trade_sn" label="订单号"></el-option>
                <el-option value="member_mobile" label="用户手机号"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="parent_type == 3">
            <el-input style="width:220px" clearable v-model="params.member_mobile" placeholder="请输入手机号搜索"></el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
            <el-button :loading="importLoading" @click="exportNotice" type="primary" size="small">导出</el-button>
          </el-form-item>
          <div class="buttonbc">
            <el-button style="position: absolute;right:0;top:10px" @click="() => { $router.go(-1); }" size="small"
              class="back_btn">
              <img src="../../assets/back.png" alt="">
            </el-button>
          </div>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column v-if="parent_type !== 3" label="流水号" min-width="150" fixed="left" prop="serial_number" />
        <el-table-column label="交易类型" min-width="150" fixed="left">
          <template slot-scope="{row}">
            <span v-if="parent_type == 2">{{ row.buy_cancel == 1 ? '订单支出' : '取消订单退回' }}</span>
            <span v-else>{{ type_filter(row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="交易日期" width="160" fixed="left">
          <template slot-scope="{row}">{{ row.creation_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="交易内容/备注" prop="content" width="250" />
        <el-table-column v-if="parent_type == 2" label="提货类型" prop="content" width="250">
          <template slot-scope="{row}">
            <span>{{ row.type == 4 ? '餐类商品订单' : (row.type == 5 ? '自提商品订单' : '0元商品订单') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户手机号" width="150" prop="member_mobile" />
        <el-table-column v-if="[1, 4].includes(parent_type)" label="单据金额" prop="account_money" />
        <el-table-column label="用户账户余额收支" min-width="150" prop="member_balance">
          <template slot="header">
            <el-tooltip style="max-width: 200px" effect="dark" placement="top">
              <label class="cursor-pointer">用户账户余额收支<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                {{ `对应单据内，用户使用${[2, 4].includes(parent_type) ? '账户余额' : '餐卡'}支付的费用` }}
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <div v-if="[2, 4].includes(parent_type)">
              <span v-if="row.member_balance !== 0">{{ row.buy_cancel == 1 ? '-' : '+' }}</span>
              <span>{{ row.member_balance }}</span>
            </div>
            <div v-else>
              <span v-if="row.member_balance !== 0">{{ [2, 3, 6, 7].includes(row.type) ? '+' : '-' }}</span>
              <span>{{ row.member_balance }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="[1, 4].includes(parent_type)" label="用户微信收支" min-width="110" prop="weixin_balance">
          <template slot-scope="{row}">
            <div v-if="[4].includes(parent_type)">
              <span v-if="row.weixin_balance !== 0">{{ row.buy_cancel == 1 ? '-' : '+' }}</span>
              <span>{{ row.weixin_balance }}</span>
            </div>
            <div v-else>
              <span v-if="row.weixin_balance !== 0">{{ [2, 3, 6, 7].includes(row.type) ? '+' : '-' }}</span>
              <span>{{ row.weixin_balance }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="([1].includes(parent_type)) || ([2, 4].includes(parent_type) && !ispetroChina)"
          label="用户卡券收支" min-width="110" prop="card_balance">
          <template slot-scope="{row}">
            <div v-if="[4].includes(parent_type)">
              <span v-if="row.card_balance !== 0">{{ row.buy_cancel == 1 ? '-' : '+' }}</span>
              <span>{{ row.card_balance }}</span>
            </div>
            <div v-else>
              <span v-if="row.card_balance !== 0">{{ [2, 3, 6, 7].includes(row.type) ? '+' : '-' }}</span>
              <span>{{ row.card_balance }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="parent_type !== 3" label="操作" fixed="right" width="80">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="infoNotice(row)">详情</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import { pickupAccountStatementList, pickupAccountStatementListTotal } from '@/api/account';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import { handleDownload } from '@/utils';
import { Foundation } from "@/../ui-utils";
export default {
  name: 'billListDetail',
  components: { EnTableLayout },
  data () {
    return {
      /**是否是中石油站点 */
      ispetroChina: false,
      importLoading: false,
      keyword: '',
      key_word: "serial_number",
      /**交易类型 */
      orderOptions: [],
      parent_type: 1,
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        sn: '',
        is_card: ''
      },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  activated () {
    console.log('activated');
    this.params.sn = this.$route.query.sn
    this.parent_type = Number(this.$route.query.type)
    this.params.account_type = this.parent_type
    this.ispetroChina = this.$route.query.ispetroChina
    switch (this.parent_type) {
      case 1:
        this.orderOptions = [
          { label: '平台商品订单支出', value: 1 },
          { label: '平台商品退款', value: 2 },
          { label: '平台商品订单取消', value: 3 }
        ]
        break;
      case 3:
        this.orderOptions = [
          { label: '线下充值', value: 6 },
          { label: '线下退款', value: 7 },
          { label: '线下消费', value: 8 },
        ]
        break;
      case 4:
        this.orderOptions = [
          { label: '商品订单支出', value: 10 },
          { label: '商品退款', value: 11 },
          { label: '商品订单取消', value: 12 }
        ]
        break;
      default:
        break;
    }
    this.$refs.enTableLayout.$refs.table.doLayout();
  },
  mounted () {
    this.params.sn = this.$route.query.sn
    this.parent_type = Number(this.$route.query.type)
    this.params.account_type = this.parent_type
    this.ispetroChina = this.$route.query.ispetroChina === 'true' ? true : false
    switch (this.parent_type) {
      case 1:
        this.orderOptions = [
          { label: '平台商品订单支出', value: 1 },
          { label: '平台商品退款', value: 2 },
          { label: '平台商品订单取消', value: 3 }
        ]
        break;
      case 2:
        this.orderOptions = [
          { label: '线下充值', value: 6 },
          { label: '线下退款', value: 7 },
          { label: '线下消费', value: 8 },
        ]
        break;
      case 4:
        this.orderOptions = [
          { label: '商品订单支出', value: 10 },
          { label: '商品退款', value: 11 },
          { label: '商品订单取消', value: 12 }
        ]
        break;
      default:
        break;
    }
    this.GET_AccountList();
  },
  methods: {
    type_filter (type) {
      switch (type) {
        case 1:
          return '平台商品订单支出'
        case 2:
          return '平台商品退款'
        case 3:
          return '平台商品订单取消'
        case 4:
          return '餐类商品订单支出'
        case 5:
          return '其他自提商品订单支出'
        case 6:
          return '线下充值'
        case 7:
          return '线下退款'
        case 10:
          return '商品订单支出'
        case 11:
          return '商品退款'
        case 12:
          return '商品订单取消'
        default:
          return '线下消费'
      }
    },
    /** 搜索事件触发 */
    searchEvent () {
      delete this.params.serial_number;
      delete this.params.trade_sn;
      delete this.params.member_mobile;
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      if (this.keyword) {
        this.params[this.key_word] = this.keyword
      }
      delete this.params.start_time;
      delete this.params.end_time;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.formData(this.params)
      console.log(params);
      if (params.type == 12) {
        params.buy_cancel = 2
        params.type = 10
      } else if (params.type == 10) {
        params.buy_cancel = 1
      }
      pickupAccountStatementList(params).then(response => {
        this.loading = false;
        if (this.parent_type == 4) {
          response.data.forEach(item => {
            if (item.type == 10 && item.buy_cancel == 2) item.type = 12
          })
        }
        this.tableData = response;
      });
    },
    infoNotice (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        path: `/order/order-list/detail/${row.trade_sn}`,
        query: {
          type: 1
        }
      })
    },
    formData (data) {//格式化表单
      let params = JSON.parse(JSON.stringify(data));
      if ([1, 4].includes(this.parent_type) == false) delete params.is_card;
      params = Object.fromEntries(Object.entries(params).filter(([key, value]) => value))
      return params;
    },
    priceFormate (data, type, buy_cancel) {
      let newPrice = ''
      if (data !== 0) {
        if (this.parent_type == 4) {//商城邮寄商品
          newPrice = buy_cancel == 1 ? '-' + data : '+' + data
        } else {
          newPrice = [2, 3, 6, 7].includes(type) ? '+' + data : '-' + data
        }
      } else {
        newPrice = data
      }
      return newPrice;
    },
    //导出
    async exportNotice () {
      this.importLoading = true;
      let filterVals = []
      let tHeaders = []
      if (this.parent_type !== 3) {
        filterVals.push('serial_number')
        tHeaders.push('流水号')
      }
      filterVals.push('typeName', 'creation_time', 'content', 'member_mobile')
      tHeaders.push('交易类型', '交易日期', '交易内容/备注', '用户手机号')
      if (this.parent_type == 2) filterVals.push('buy'), tHeaders.push('提货类型')
      if ([1, 4].includes(this.parent_type)) filterVals.push('account_money'), tHeaders.push('单据金额')
      filterVals.push('member_balance')
      tHeaders.push('用户账户余额收支')
      if ([1, 4].includes(this.parent_type)) filterVals.push('weixin_balance'), tHeaders.push('用户微信收支')
      if (([1].includes(this.parent_type)) || ([2, 4].includes(this.parent_type) && !this.ispetroChina)) filterVals.push('card_balance'), tHeaders.push('用户卡券收支')

      let params = this.formData(this.params)
      params.page_no = 1;
      params.page_size = 99999;
      if (params.type == 12) {
        params.buy_cancel = 2
        params.type = 10
      } else if (params.type == 10) {
        params.buy_cancel = 1
      }
      const _this = this;
      //查询合计
      let total_params = JSON.parse(JSON.stringify(params));
      delete total_params.page_no;
      delete total_params.page_size;
      const all_total = await pickupAccountStatementListTotal(total_params);

      pickupAccountStatementList(params).then((response) => {
        if (this.parent_type == 4) {
          response.data.forEach(item => {
            if (item.type == 10 && item.buy_cancel == 2) item.type = 12
          })
        }
        response.data.forEach(item => {
          if ([1, 4].includes(this.parent_type)) {
            item.weixin_balance = _this.priceFormate(item.weixin_balance, item.type, item.buy_cancel)
            item.card_balance = _this.priceFormate(item.card_balance, item.type, item.buy_cancel)
          }
          if (this.parent_type == 2) {
            item.typeName = item.buy_cancel == 1 ? '订单支出' : '取消订单退回'
            item.buy = item.type == 4 ? '餐类商品订单' : (item.type == 5 ? '自提商品订单' : '0元商品订单')
            item.member_balance = item.buy_cancel == 1 ? '-' + item.member_balance : '+' + item.member_balance
            item.card_balance = _this.priceFormate(item.card_balance, item.type, item.buy_cancel)
          } else {
            item.typeName = _this.type_filter(item.type)
            item.member_balance = _this.priceFormate(item.member_balance, item.type, item.buy_cancel)
          }
          item.creation_time = Foundation.unixToDate(item.creation_time)
        });
        let fileName
        switch (this.parent_type) {
          case 1:
            fileName = '好物到家账单明细'
            break
          case 2:
            fileName = '自提商品账单明细'
            break
          case 3:
            fileName = '自提商品账单明细'
            break
          case 4:
            fileName = '商城邮寄商品账单明细'
            break

        }

        //增加合计
        if (this.parent_type == 3) {
          response.data.push({
            typeName: '合计',
            member_balance: all_total.member_balance //用户账户余额收支
          })
        } else if (this.parent_type == 2) {
          response.data.push({
            serial_number: '合计',
            member_balance: all_total.member_balance, //用户账户余额收支
            card_balance: all_total.card_balance // 用户卡券收支
          })
        } else {
          response.data.push({
            serial_number: '合计',
            account_money: all_total.account_money,//单据金额
            member_balance: all_total.member_balance, //用户账户余额收支
            weixin_balance: all_total.weixin_balance,// 用户微信收支
            card_balance: all_total.card_balance // 用户卡券收支
          })
        }
        handleDownload(response.data, tHeaders, filterVals, fileName);
        this.importLoading = false;
      })
        .catch((res) => {
          this.importLoading = false;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
